import classNames from 'classnames';
import { ReactComponent as SpeakerIcon } from '../images/speaker.svg';
import Spinner from './Spinner';
import { STEPS_SELECTOR_CLASSES } from '../utils/constants';

interface SpeakerButtonProps {
  isFetchingAudio: boolean;
  handleSpeakerClick: () => void;
  isPlaying: boolean;
}

const SpeakerButton = ({
  isFetchingAudio,
  handleSpeakerClick,
  isPlaying,
}: SpeakerButtonProps) => {
  return (
    <>
      {isFetchingAudio ? (
        <Spinner />
      ) : (
        <button
          title='Play message'
          type='button'
          onClick={handleSpeakerClick}
          className={`${STEPS_SELECTOR_CLASSES.SPEAKER_BUTTON}`}
        >
          <SpeakerIcon className={classNames(isPlaying && 'animate-blink')} />
        </button>
      )}
    </>
  );
};

export default SpeakerButton;
