import { FC } from 'react';

interface DeleteChatModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  teacherName: string;
}

const DeleteChatModal: FC<DeleteChatModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  teacherName,
}) => {
  if (!isOpen) return null;

  return (
    <dialog id='delete_chat_modal' className='modal modal-open'>
      <div className='modal-box'>
        <h3 className='font-bold text-lg'>Restart Chat</h3>
        <p className='py-4'>
          Are you sure you want to restart this chat with {teacherName}?
        </p>
        <div className='modal-action'>
          <button className='btn' onClick={onClose}>
            Cancel
          </button>
          <button className='btn btn-error' onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
      <form method='dialog' className='modal-backdrop'>
        <button onClick={onClose}>close</button>
      </form>
    </dialog>
  );
};

export default DeleteChatModal;
