import { Dispatch, SetStateAction } from 'react';

const Footer = ({
  setIsContactFormTakeoverOpen,
}: {
  setIsContactFormTakeoverOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <footer className='text-white py-8'>
      <div className='container mx-auto px-4 text-center gap-y-4 flex flex-col justify-center items-center'>
        <button
          type='button'
          onClick={() => setIsContactFormTakeoverOpen(true)}
          className='bg-white hover:bg-gray-200 font-bold text-black p-2 rounded-md transition duration-200 w-fit shadow-lg'
        >
          Contact us
        </button>
        <p>&copy; 2024 Lingua Chat Inc. All rights reserved.</p>
        <div className='flex gap-x-2 items-center'>
          <a
            href='/lingua-chat-terms-of-use.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Use
          </a>
          <p className='text-xl'>|</p>
          <a
            href='/lingua-chat-privacy-policy.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
