import { IconType } from 'react-icons';
import {
  FiUser,
  FiGlobe,
  FiLock,
  FiCreditCard,
  FiTrash2,
  FiLogOut,
  FiMessageSquare,
  FiChevronRight,
} from 'react-icons/fi';

interface ProfileButtonProps {
  text: string;
  handleClick: () => void;
  variant?: 'default' | 'danger' | 'primary';
  size?: 'default' | 'small';
}

const getIcon = (text: string): IconType => {
  if (text.toLowerCase().includes('username')) return FiUser;
  if (text.toLowerCase().includes('native language')) return FiGlobe;
  if (text.toLowerCase().includes('password')) return FiLock;
  if (text.toLowerCase().includes('subscription')) return FiCreditCard;
  if (text.toLowerCase().includes('chats')) return FiMessageSquare;
  if (text.toLowerCase().includes('delete')) return FiTrash2;
  if (text.toLowerCase().includes('logout')) return FiLogOut;
  return FiChevronRight;
};

const getVariantStyles = (variant: 'default' | 'danger' | 'primary') => {
  switch (variant) {
    case 'danger':
      return 'border-red-200 text-red-600 hover:bg-red-50 focus:ring-red-500';
    case 'primary':
      return 'border-blue-200 text-blue-600 hover:bg-blue-50 focus:ring-blue-500';
    default:
      return 'border-gray-200 text-gray-700 hover:bg-gray-50 focus:ring-gray-500';
  }
};

const getSizeStyles = (size: 'default' | 'small') => {
  switch (size) {
    case 'small':
      return 'px-3 py-1.5 text-sm';
    default:
      return 'px-4 py-3';
  }
};

const ProfileButton = ({
  text,
  handleClick,
  variant = 'default',
  size = 'default',
}: ProfileButtonProps) => {
  const Icon = getIcon(text);
  const sizeStyles = getSizeStyles(size);
  const showChevron = size === 'default';

  return (
    <button
      onClick={handleClick}
      className={`w-full flex items-center justify-between ${sizeStyles} bg-white border rounded-lg font-medium transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-opacity-50 group ${getVariantStyles(
        variant,
      )}`}
    >
      <div className='flex items-center space-x-3'>
        <Icon className={size === 'small' ? 'w-4 h-4' : 'w-5 h-5'} />
        <span>{text}</span>
      </div>
      {showChevron && (
        <FiChevronRight className='w-4 h-4 opacity-50 group-hover:opacity-75 group-hover:translate-x-0.5 transition-all' />
      )}
    </button>
  );
};

export default ProfileButton;
