import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from 'react';
import { PROFILE_PAGE_TITLES } from '../utils/constants';

interface ProfileUpdateModalProps {
  title: string;
  handleSubmit: (value: string) => void;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  currentUsername?: string;
  currentNativeLanguage?: string;
  isOpen: boolean;
  onClose: () => void;
}

const ProfileUpdateModal = ({
  title,
  handleSubmit,
  errorMessage,
  setErrorMessage,
  currentUsername = '',
  currentNativeLanguage = '',
  isOpen,
  onClose,
}: ProfileUpdateModalProps) => {
  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      modalRef.current?.showModal();
    } else {
      modalRef.current?.close();
    }
  }, [isOpen]);

  const getInitialValue = () => {
    if (title === PROFILE_PAGE_TITLES.UPDATE_USERNAME) return currentUsername;
    if (title === PROFILE_PAGE_TITLES.UPDATE_NATIVE_LANGUAGE)
      return currentNativeLanguage;
    return '';
  };

  const [value, setValue] = useState(getInitialValue());

  let label = title.slice(7).charAt(0).toUpperCase() + title.slice(8);
  if (title === PROFILE_PAGE_TITLES.UPDATE_USERNAME)
    label = 'What should tutors call you?';
  if (title === PROFILE_PAGE_TITLES.UPDATE_PASSWORD) label = 'New Password';

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit(value);
  };

  return (
    <dialog ref={modalRef} className='modal' onClose={onClose}>
      <div className='modal-box max-w-sm'>
        <h3 className='font-bold text-lg mb-4'>{title}</h3>

        <form onSubmit={handleFormSubmit} className='space-y-4'>
          {errorMessage && (
            <div className='alert alert-error'>
              <span className='text-sm'>{errorMessage}</span>
            </div>
          )}

          <div className='form-control w-full'>
            <label className='label'>
              <span className='label-text font-medium'>{label}</span>
            </label>
            <input
              value={value}
              onChange={e => {
                setErrorMessage('');
                setValue(e.target.value);
              }}
              className='input input-bordered w-full'
              required
            />
          </div>

          <div className='modal-action'>
            <button type='button' className='btn' onClick={onClose}>
              Cancel
            </button>
            <button
              type='submit'
              className='btn bg-blue-600 hover:bg-blue-700 text-white border-none'
            >
              Update
            </button>
          </div>
        </form>
      </div>
      <form method='dialog' className='modal-backdrop'>
        <button onClick={onClose}>close</button>
      </form>
    </dialog>
  );
};

export default ProfileUpdateModal;
