import React, { useEffect, useRef } from 'react';

interface ProfileConfirmModalProps {
  title: string;
  handleConfirm: () => void;
  errorMessage: string;
  isOpen: boolean;
  onClose: () => void;
}

const ProfileConfirmModal = ({
  title,
  handleConfirm,
  errorMessage,
  isOpen,
  onClose,
}: ProfileConfirmModalProps) => {
  const modalRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (isOpen) {
      modalRef.current?.showModal();
    } else {
      modalRef.current?.close();
    }
  }, [isOpen]);

  return (
    <dialog ref={modalRef} className='modal' onClose={onClose}>
      <div className='modal-box max-w-sm'>
        <h3 className='font-bold text-lg mb-4'>{title}</h3>

        {errorMessage && (
          <div className='alert alert-error mb-4'>
            <span className='text-sm'>{errorMessage}</span>
          </div>
        )}

        <p className='py-4'>
          Are you sure you want to{' '}
          {title.charAt(0).toLowerCase() + title.slice(1)}?
        </p>

        <div className='modal-action'>
          <button className='btn' onClick={onClose}>
            Cancel
          </button>
          <button
            className='btn btn-error'
            onClick={() => {
              handleConfirm();
              onClose();
            }}
          >
            Confirm
          </button>
        </div>
      </div>
      <form method='dialog' className='modal-backdrop'>
        <button onClick={onClose}>close</button>
      </form>
    </dialog>
  );
};

export default ProfileConfirmModal;
