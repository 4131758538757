import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const FAQ = () => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  const toggleFaq = (index: number) => {
    setOpenIndexes(
      prev =>
        prev.includes(index)
          ? prev.filter(i => i !== index) // remove if already open
          : [...prev, index], // add if closed
    );
  };

  const faqs = [
    {
      question: 'How effective is AI for language learning?',
      answer:
        'Studies show that consistent practice with AI language partners can significantly improve fluency and confidence.',
    },
    {
      question: 'Can AI really understand and correct my mistakes?',
      answer:
        'Yes! Our AI uses advanced natural language processing to identify grammatical errors and vocabulary mistakes, providing instant, accurate corrections.',
    },
    {
      question: 'How do AI tutors compare to traditional tutors?',
      answer:
        "While both are valuable, AI tutors offer 24/7 availability, consistent feedback, and unlimited practice at a fraction of the cost. Plus, there's no judgment or scheduling hassles.",
    },
  ];

  return (
    <section className='max-w-3xl mx-auto px-3 py-8 sm:px-6 lg:px-8'>
      <h2 className='text-2xl md:text-3xl font-bold text-white text-center mb-6'>
        Frequently Asked Questions
      </h2>
      <div className='space-y-4'>
        {faqs.map((faq, index) => (
          <div
            key={index}
            className='bg-white rounded-xl shadow-lg overflow-hidden'
          >
            <button
              onClick={() => toggleFaq(index)}
              className='w-full p-6 text-left flex justify-between items-center hover:bg-gray-50'
            >
              <h3 className='text-xl font-bold text-gray-900'>
                {faq.question}
              </h3>
              <FontAwesomeIcon
                icon={openIndexes.includes(index) ? faChevronUp : faChevronDown}
                className='text-gray-500 ml-4 flex-shrink-0 w-5 h-5'
              />
            </button>

            {openIndexes.includes(index) && (
              <div className='p-6 pt-0'>
                <p className='text-gray-600 font-semibold'>{faq.answer}</p>
                {index === 0 && <Sources />}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

const Sources = () => {
  const sources = [
    {
      href: 'https://pmc.ncbi.nlm.nih.gov/articles/PMC10658009/',
      text: 'Artificial intelligence in language instruction: impact on English learning achievement, L2 motivation, and self-regulated learning',
    },
    {
      href: 'https://www.sciencedirect.com/science/article/pii/S2666920X24000316',
      text: 'Transforming language education: A systematic review of AI-powered chatbots for English as a foreign language speaking practice',
    },
    {
      href: 'https://pmc.ncbi.nlm.nih.gov/articles/PMC10652775/',
      text: 'Artificial intelligence-based language learning: illuminating the impact on speaking skills and self-regulation in Chinese EFL context',
    },
  ];

  return (
    <div className='mt-2 font-semibold'>
      <p className='mb-2'>Sources:</p>
      <div className='flex flex-col gap-2'>
        {sources.map(({ href, text }, index) => (
          <a
            key={index}
            href={href}
            target='_blank'
            rel='noreferrer noopener'
            className='text-blue-500 hover:underline'
          >
            {text}
          </a>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
