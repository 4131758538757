// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import SubscribeButton from '../components/SubscribeButton';
// import { useUserContext } from '../contexts/UserContext';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LogoAndTitle from '../components/LogoAndTitle';
import useIsDesktop from '../hooks/useIsDesktop';
// import { PATHS } from '../utils/constants';

const BENEFITS = [
  'Unlimited AI language practice, 24/7',
  'Multiple tutors & languages',
  'Personalized feedback & corrections',
];

const TESTIMONIALS = [
  {
    text: "I've made more progress in 2 weeks with Lingua Chat than in 6 months of traditional learning!",
    author: 'Sarah M.',
  },
  {
    text: "The personalized feedback is incredible. It's like having a private tutor available 24/7.",
    author: 'David L.',
  },
];

const SubscriptionPage = () => {
  // const { user } = useUserContext();
  // const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  // useEffect(() => {
  //   if (user.planActive) {
  // navigate(PATHS.CHAT);
  //todo do i still need something ehre? if so use diff logic
  //   }
  // }, [navigate, user]);

  return (
    <div className='min-h-screen bg-gradient-to-br from-blue-600 via-blue-500 to-blue-600 text-white'>
      <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 lg:py-20'>
        <LogoAndTitle />

        {/* Main Content Container */}
        <div className='mx-auto space-y-12 mt-12 lg:mt-8'>
          {/* Header */}
          <div className='text-center space-y-6 mb-12'>
            <h1 className='text-4xl lg:text-5xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-white to-blue-100'>
              Continue Your Language Learning Journey
            </h1>
            <p className='text-xl lg:text-2xl text-blue-100 font-semibold'>
              Your free trial has ended, but your progress doesn't have to.
            </p>

            {/* Top CTA */}
            <div className='mt-8'>
              <SubscribeButton />
              <p className='text-center font-bold mt-4'>
                30-day money-back guarantee, no questions asked
              </p>
            </div>
          </div>

          {/* Main Grid Layout */}
          <div className='grid md:grid-cols-2 gap-8 items-start'>
            {/* Left Column - Pricing Card */}
            <div className='bg-white rounded-2xl p-8 backdrop-blur-lg border border-white/20 shadow-xl text-gray-800'>
              <div className='space-y-6'>
                <div className='text-center'>
                  <p className='text-2xl font-extrabold mb-2'>
                    Only $4.99 / month
                  </p>
                </div>

                <div className='space-y-4'>
                  <div className='p-6 bg-gray-100 rounded-xl border border-gray-200'>
                    <p className='font-bold mb-3 text-gray-900'>
                      That's less than:
                    </p>
                    <div className='space-y-3'>
                      <div className='flex items-center gap-3 font-semibold text-gray-700'>
                        <span className='text-xl'>👨‍🏫</span>
                        <div>
                          <p>Traditional tutor</p>
                          <p className='text-blue-600'>$7-$25+ per lesson</p>
                        </div>
                      </div>
                      <div className='flex items-center gap-3 font-semibold text-gray-700'>
                        <span className='text-xl'>📚</span>
                        <div>
                          <p>Language textbook</p>
                          <p className='text-blue-600'>$30+</p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='p-6 bg-gray-100 rounded-xl border border-gray-200'>
                    <p className='font-bold mb-3 text-gray-900'>
                      Included benefits:
                    </p>
                    {BENEFITS.map((benefit, index) => (
                      <div
                        key={index}
                        className='flex items-center gap-3 mb-3 last:mb-0'
                      >
                        <CheckCircleIcon className='text-blue-600 h-5 w-5 flex-shrink-0' />
                        <span className='font-semibold text-gray-700'>
                          {benefit}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            {/* Right Column - Testimonials */}
            <div className='space-y-6'>
              {TESTIMONIALS.map((testimonial, index) => (
                <div
                  key={index}
                  className='bg-white p-6 rounded-xl backdrop-blur-lg border border-white/20 shadow-xl transform hover:scale-102 transition-transform duration-200 text-gray-800'
                >
                  <div className='flex gap-1 mb-3'>
                    {[...Array(5)].map((_, i) => (
                      <StarIcon key={i} className='text-yellow-400 h-5 w-5' />
                    ))}
                  </div>
                  <p className='text-lg font-medium mb-4'>
                    "{testimonial.text}"
                  </p>
                  <p className='text-gray-600 font-medium'>
                    - {testimonial.author}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Bottom CTA */}
          {!isDesktop && (
            <div className='text-center pt-8 pb-4'>
              <div className='max-w-xl mx-auto'>
                <h2 className='text-2xl font-bold mb-4 text-white'>
                  Ready to Continue Learning?
                </h2>
                <SubscribeButton />
                <p className='text-center font-bold mt-4'>
                  30-day money-back guarantee, no questions asked
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
