import { useNavigate } from 'react-router-dom';
import { PATHS } from '../utils/constants';
import { useAuthContext } from '../contexts/AuthContext';

const LogoAndTitle = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();

  const handleClick = () => {
    if (currentUser) return;

    navigate(PATHS.LANDING);
  };

  return (
    <button
      onClick={handleClick}
      className='flex gap-x-2 items-center absolute top-5 left-5'
    >
      <img src='/favicon.ico' alt='' className='h-10 w-10' />
      <h1 className='text-2xl font-semibold text-white'>Lingua Chat</h1>
    </button>
  );
};

export default LogoAndTitle;
