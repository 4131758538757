import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Spinner from './Spinner';
import { STEPS_SELECTOR_CLASSES } from '../utils/constants';

interface CorrectionButtonProps {
  isFetchingCorrection: boolean;
  handleCorrectionClick: () => void;
}

//todo consider jsut showing for last user message
const CorrectionButton = ({
  isFetchingCorrection,
  handleCorrectionClick,
}: CorrectionButtonProps) => {
  return (
    <>
      {isFetchingCorrection ? (
        <Spinner white />
      ) : (
        <button
          title='Get correction'
          type='button'
          onClick={handleCorrectionClick}
          className={`${STEPS_SELECTOR_CLASSES.CORRECTION_BUTTON}`}
        >
          <AutoFixHighIcon className='text-white scale-75' />
        </button>
      )}
    </>
  );
};

export default CorrectionButton;
