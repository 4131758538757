import classNames from 'classnames';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useUserContext } from '../contexts/UserContext';
import translationAPI from '../api/translationAPI';
import suggestionAPI from '../api/suggestionAPI';
import { useTeachersContext } from '../contexts/TeachersContext';
import { LANGUAGES, LANGUAGES_WITH_TRANSLITERATION } from '../utils/constants';
import fetchCorrectionAPI from '../api/correctionAPI';
import { useConfigContext } from '../contexts/ConfigContext';
import { MESSAGES_CONTAINER } from './Messages';
import TranslationDropdown from './TranslationDropdown';
import SuggestionDropdown from './SuggestionDropdown';
import Word from './Word';
import AIMessageButtons from './AIMessageButtons';
import UserMessageButtons from './UserMessageButtons';
import CorrectionDropdown from './CorrectionDropdown';
import toast from 'react-hot-toast';
import audioAPI from '../api/audioAPI';

interface MessageProps {
  content: string;
  transliteration: string | null;
  _id: string;
  stopAllAudios: () => void;
  audioMap: Record<string, HTMLAudioElement>;
  setAudioMap: Dispatch<SetStateAction<Record<string, HTMLAudioElement>>>;
  setPlayingStateMap: Dispatch<SetStateAction<Record<string, boolean>>>;
  playingStateMap: Record<string, boolean>;
  isLastAIMessage: boolean;
  isTypingAnimation: boolean;
  isUser: boolean;
  isFirstMessage: boolean;
}

//todo push down click handlers to respective buttons, keep state here
const Message = ({
  content,
  transliteration,
  _id,
  stopAllAudios,
  audioMap,
  setAudioMap,
  playingStateMap,
  setPlayingStateMap,
  isLastAIMessage,
  isTypingAnimation,
  isUser,
  isFirstMessage,
}: MessageProps) => {
  const { user, settingsMap } = useUserContext();
  const { teachersMap } = useTeachersContext();
  const { config } = useConfigContext();
  const messageRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [isFetchingCorrection, setIsFetchingCorrection] = useState(false);
  const [correction, setCorrection] = useState('');
  const [isCorrectionDropdownOpen, setIsCorrectionDropdownOpen] =
    useState(false);
  const [isFetchingAudio, setIsFetchingAudio] = useState(false);
  const [isTranslationDropdownOpen, setIsTranslationDropdownOpen] =
    useState(false);
  const [translation, setTranslation] = useState('');
  const [isFetchingTranslation, setIsFetchingTranslation] = useState(false);
  const [isSuggestionDropdownOpen, setIsSuggestionDropdownOpen] =
    useState(false);
  const [suggestion, setSuggestion] = useState('');
  const [isFetchingSuggestion, setIsFetchingSuggestion] = useState(false);
  // const [wordTranslations, setWordTranslations] = useState<
  //   Record<string, string>
  // >({});

  const { showTransliteration, selectedTeacher } =
    settingsMap[user.selectedLanguage];
  const teacher = teachersMap[selectedTeacher];
  const [showMessageTransliteration, setShowMessageTransliteration] =
    useState(showTransliteration);
  const messageEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    !isFirstMessage &&
      showMessageTransliteration &&
      messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isFirstMessage, showMessageTransliteration]);

  useEffect(() => {
    setShowMessageTransliteration(showTransliteration);
  }, [showTransliteration]);

  /**
   * Handle scrolling to bottom if user opens last messsage's translation or suggestion dropdown
   */
  useEffect(() => {
    if (!messageRef.current || !dropdownRef.current) return;

    if (isTranslationDropdownOpen || isSuggestionDropdownOpen) {
      const dropdownHeight = dropdownRef.current.offsetHeight;
      const messageRect = messageRef.current.getBoundingClientRect();
      let overflow =
        messageRect.bottom + dropdownHeight - window.innerHeight + 300;

      //note abirtary value that was large enough
      if (isLastAIMessage) {
        overflow += 300;
      }

      const messagesContainer = document.getElementById(MESSAGES_CONTAINER);

      if (overflow > 0 && messagesContainer) {
        messagesContainer.scrollBy(0, overflow);
      }
    }
  }, [isTranslationDropdownOpen, isSuggestionDropdownOpen, isLastAIMessage]);

  const handleCorrectionClick = async () => {
    if (correction) {
      setIsCorrectionDropdownOpen(!isCorrectionDropdownOpen);
      return;
    }

    setIsFetchingCorrection(true);

    try {
      const correction = await fetchCorrectionAPI.fetchCorrection({
        prevAIMessage: '',
        content: content,
        language: user.selectedLanguage,
        userNativeLanguage: user.nativeLanguage,
      });

      setCorrection(correction);
      setIsCorrectionDropdownOpen(true);
    } catch (error) {
      toast.error('Error fetching correction. Please try again.');
    } finally {
      setIsFetchingSuggestion(false);
    }

    setIsFetchingCorrection(false);
  };

  const handleSpeakerClick = async () => {
    const audio = audioMap[_id];

    if (audio) {
      audio.onplay = () => setPlayingStateMap({ [_id]: true });
      audio.onpause = () => setPlayingStateMap({});
      audio.onended = () => setPlayingStateMap({});

      if (audio.paused) {
        stopAllAudios();
        audio.play();
      } else {
        audio.pause();
        audio.currentTime = 0;
      }

      return;
    } else {
      setIsFetchingAudio(true);

      try {
        stopAllAudios();
        setPlayingStateMap({}); //note do i need map?

        const audioBlob = await audioAPI.fetchAudio(
          content,
          teacher.isFemale,
          teacher.languageCode,
          teacher.voice,
          teacher.azureVoice,
          teacher.ttsProvider,
        );

        const audioUrl = URL.createObjectURL(
          new Blob([audioBlob], { type: 'audio/mpeg' }),
        );

        const newAudio = new Audio(audioUrl);

        setAudioMap(prev => ({
          ...prev,
          [_id]: newAudio,
        }));

        setPlayingStateMap({ [_id]: true });

        //todo stopAllAudios here?
        stopAllAudios();

        newAudio.play();
        newAudio.onended = () => setPlayingStateMap({});
      } catch (error) {
        toast.error('Error fetching audio. Please try again.');
      }

      setIsFetchingAudio(false);
    }
  };

  const handleTranslationClick = async () => {
    if (!translation) {
      setIsFetchingTranslation(true);

      try {
        const translation = await translationAPI.fetchTranslation(
          user.nativeLanguage,
          content,
        );

        setTranslation(translation);
        setIsSuggestionDropdownOpen(false);
        setIsTranslationDropdownOpen(true);
      } catch (error) {
        toast.error('Error fetching translation. Please try again.');
      } finally {
        setIsFetchingTranslation(false);
      }
    } else {
      isSuggestionDropdownOpen && setIsSuggestionDropdownOpen(false);
      setIsTranslationDropdownOpen(!isTranslationDropdownOpen);
    }
  };

  const handleWordClick = async () => {
    //word: string
    // if (!wordTranslations[word]) {
    //   try {
    //     const translation = await translationAPI.fetchTranslation(
    //       user.nativeLanguage,
    //       word,
    //     );
    //     setWordTranslations(prev => ({ ...prev, [word]: translation }));
    //   } catch (error) {
    //     console.log('translation error for one word ', error);
    //   }
    // }
  };

  const handleSuggestionClick = async () => {
    if (!suggestion) {
      setIsFetchingSuggestion(true);

      try {
        const suggestionResponse = await suggestionAPI.fetchSuggestion({
          content,
          language: user.selectedLanguage,
          userNativeLanguage: user.nativeLanguage,
        });

        setSuggestion(suggestionResponse);
        setIsTranslationDropdownOpen(false);
        setIsSuggestionDropdownOpen(true);
      } catch (error) {
        toast.error('Error fetching suggestion. Please try again.');
      } finally {
        setIsFetchingSuggestion(false);
      }
    } else {
      isTranslationDropdownOpen && setIsTranslationDropdownOpen(false);
      setIsSuggestionDropdownOpen(!isSuggestionDropdownOpen);
    }
  };

  const handleTransliterationClick = async () => {
    setShowMessageTransliteration(!showMessageTransliteration);
  };

  return (
    <div
      ref={messageRef}
      className={classNames(
        'flex flex-col mb-4',
        isUser ? 'self-end' : 'self-start',
      )}
    >
      <div
        className={classNames(
          'max-w-xs md:max-w-md lg:max-w-lg xl:max-w-xl rounded-xl px-6 py-3 flex flex-col',
          isUser ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black',
        )}
      >
        {isTypingAnimation && <div className='dot-typing m-3' />}

        <div className='mb-2'>
          {content.split(' ').map((word, i) => (
            <Word
              {...{
                word,
                handleWordClick,
                translation: '', // wordTranslations[word],
                key: i,
              }}
            />
          ))}
        </div>

        {LANGUAGES_WITH_TRANSLITERATION.includes(user.selectedLanguage) &&
          !isUser &&
          transliteration &&
          (showMessageTransliteration || showTransliteration) && (
            <p className='mb-2'>{transliteration}</p>
          )}

        {config.featureFlags.features.corrections &&
          isUser &&
          !isTypingAnimation && (
            <UserMessageButtons
              {...{ isFetchingCorrection, handleCorrectionClick }}
            />
          )}

        {!isUser && !isTypingAnimation && (
          <AIMessageButtons
            {...{
              isFetchingAudio,
              handleSpeakerClick,
              isPlaying: playingStateMap[_id],
              isFetchingTranslation,
              handleTranslationClick,
              isFetchingSuggestion,
              handleSuggestionClick,
              isLastAIMessage,
              handleTransliterationClick,
              showTransliteration,
              isChinese: user.selectedLanguage === LANGUAGES.CHINESE,
            }}
          />
        )}

        {config.featureFlags.features.corrections &&
          isCorrectionDropdownOpen && (
            <CorrectionDropdown {...{ dropdownRef, correction }} />
          )}

        {isLastAIMessage && isSuggestionDropdownOpen && (
          <SuggestionDropdown {...{ dropdownRef, suggestion }} />
        )}

        {isTranslationDropdownOpen && !isUser && (
          <TranslationDropdown {...{ dropdownRef, translation }} />
        )}
      </div>
      <div ref={messageEndRef} />
    </div>
  );
};

export default Message;
