import { useEffect, useRef, useState } from 'react';
import { useConfigContext } from '../contexts/ConfigContext';

const useAppUpdates = () => {
  const { config } = useConfigContext();
  const previousReleaseRef = useRef(config.releaseDate);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  useEffect(() => {
    const hasReleaseDateDiff =
      previousReleaseRef.current !== config.releaseDate;

    if (!hasReleaseDateDiff) return;

    if (config.forceRefresh) {
      window.location.reload();
    } else {
      setIsUpdateModalOpen(true);
    }
  }, [config.releaseDate, config.forceRefresh]);

  const handleUpdateConfirm = () => {
    window.location.reload();
  };

  const handleUpdateCancel = () => {
    setIsUpdateModalOpen(false);
    previousReleaseRef.current = config.releaseDate;
  };

  return {
    isUpdateModalOpen,
    handleUpdateConfirm,
    handleUpdateCancel,
  };
};

export default useAppUpdates;
