import TryForFreeButton from './TryForFreeButton';

const CTA = () => {
  return (
    <section className='text-white pt-32'>
      <div className='container mx-auto px-2 text-center gap-y-3 flex flex-col items-center'>
        <h1 className='text-3xl md:text-4xl font-bold'>
          Practice languages with AI
        </h1>
        <p className='text-lg md:text-xl font-medium mb-4'>
          Chat with AI tutors anytime, anywhere for a fraction of the cost of
          traditional tutors.
        </p>
        <TryForFreeButton />
      </div>
    </section>
  );
};

export default CTA;
