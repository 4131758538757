import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';

interface ReviewProps {
  image: string;
  name: string;
  location: string;
  review: string;
}

const Review = ({ image, name, location, review }: ReviewProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [shouldLoad, setShouldLoad] = useState(false);
  const imageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setShouldLoad(true);
          observer.disconnect();
        }
      },
      { rootMargin: '50px' },
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className='bg-white text-black rounded-xl p-4 flex flex-col justify-between h-full shadow-lg'>
      <h1 className='font-medium'>{review}</h1>
      <div className='flex items-center mt-4'>
        <div ref={imageRef} className='relative w-12 h-12 mr-2'>
          <div
            className={classNames(
              'absolute inset-0 rounded-full',
              isImageLoading && 'animate-pulse bg-gray-300',
            )}
          />
          {shouldLoad && (
            <img
              src={image}
              alt=''
              onLoad={() => setIsImageLoading(false)}
              className={classNames(
                'absolute inset-0 rounded-full overflow-hidden object-cover w-full h-full',
                isImageLoading && 'opacity-0',
              )}
            />
          )}
        </div>
        <div>
          <p className='font-bold'>{name}</p>
          <p className='font-semibold'>{location}</p>
        </div>
      </div>
    </div>
  );
};

export default Review;
