import FeatureCard from './FeatureCard';
import {
  faComments,
  faLanguage,
  faLightbulb,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

const FeatureCards = () => {
  return (
    <section className='py-8'>
      <div className='container mx-auto px-4'>
        <h2 className='text-2xl md:text-3xl font-bold text-center mb-6 text-white'>
          Features
        </h2>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8'>
          <FeatureCard
            icon={faComments}
            title='Chat with AI tutors via voice or text'
            description='Engage in real-time conversations with AI tutors to improve your language skills.'
          />
          <FeatureCard
            icon={faUsers}
            title='Each language has multiple tutors with distinct personalities'
            description='Learn from a variety of AI tutors, each with unique teaching styles and interests.'
          />
          <FeatureCard
            icon={faLanguage}
            title='Get translations with the click of a button'
            description={`Instantly translate your tutor's messages to understand and communicate better.`}
          />
          <FeatureCard
            icon={faLightbulb}
            title='Get suggestions on how to reply'
            description='Receive smart suggestions to improve your responses and language skills.'
          />
        </div>
      </div>
    </section>
  );
};

export default FeatureCards;
