import { StepType, TourProvider } from '@reactour/tour';
import { PropsWithChildren } from 'react';
import { STEPS_SELECTORS } from '../utils/constants';

const Tutorial = ({ children }: PropsWithChildren) => {
  const getStep = (
    selector: string,
    content: JSX.Element | string,
  ): StepType => ({
    selector: selector,
    content: <div className='p-6 text-center font-bold'>{content}</div>,
  });

  const steps: StepType[] = [
    {
      ...getStep(
        STEPS_SELECTORS.CHAT_HEADER_SELECTOR,
        <div className='flex flex-col gap-1 p-2'>
          <p>Welcome to Lingua Chat!</p>
          <p>Let's show you around.</p>
        </div>,
      ),
      position: 'center',
      padding: 0,
    },
    getStep(
      STEPS_SELECTORS.CHANGE_LANGUAGE_OR_TEACHER_BUTTON_SELECTOR,
      <p>Select teacher or language</p>,
    ),
    getStep(STEPS_SELECTORS.AUDIO_TOGGLE_SELECTOR, 'Mute teacher'),
    getStep(STEPS_SELECTORS.SETTINGS_BUTTON_SELECTOR, <p>Language settings</p>),
    getStep(
      STEPS_SELECTORS.HAMBURGER_BUTTON_SELECTOR,
      <div>
        <p>Menu button</p>
        <p>You can leave feedback here</p>
        <p>(any is greatly appreciated!)</p>
      </div>,
    ),
    // getStep(
    //   STEPS_SELECTORS.SPEAKER_BUTTON_SELECTOR,
    //   <p>Click here to play a message</p>,
    // ),
    // getStep(
    //   STEPS_SELECTORS.TRANSLATION_BUTTON_SELECTOR,
    //   <p>Click here for a translation</p>,
    // ),
    // getStep(
    //   STEPS_SELECTORS.SUGGESTION_BUTTON_SELECTOR,
    //   <p>Click here for a suggestion on how to respond</p>,
    // ),
    {
      ...getStep(
        STEPS_SELECTORS.CHAT_HEADER_SELECTOR,
        <div className='flex flex-col gap-2'>
          <p>It's helpful to tell your tutor what you'd like to focus on.</p>
          <p>
            For example, "Teach me how to order in a cafe, then let's practice
            it."
          </p>
          <p>Have fun!</p>
        </div>,
      ),
      position: 'center',
      padding: 0,
    },
  ];

  return (
    <TourProvider
      steps={steps}
      disableInteraction={true}
      startAt={0}
      styles={{
        badge: (base: { [key: string]: string }) => ({
          ...base,
          display: 'none',
        }),
        popover: (base: { [key: string]: string }) => ({
          ...base,
          borderRadius: '12px',
          padding: '1.5rem',
        }),
        close: (base: { [key: string]: string }) => ({
          ...base,
          width: '2rem',
          height: '2rem',
          padding: '0.75rem',
          borderRadius: '8px',
          backgroundColor: '#f3f4f6',
          color: '#374151',
          position: 'absolute',
          top: '0.75rem',
          right: '0.75rem',
          ':hover': {
            backgroundColor: '#e5e7eb',
          },
        }),
      }}
    >
      {children}
    </TourProvider>
  );
};

export default Tutorial;
