import { FormEvent, useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Navigate, useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import auth from '../firebase';
import { DEV_TEAM_EMAIL, PATHS } from '../utils/constants';
import { useAuthContext } from '../contexts/AuthContext';
import LogoAndTitle from '../components/LogoAndTitle';
import toast from 'react-hot-toast';

const inputClasses =
  'w-full px-3 py-2 border-2 rounded-lg focus:border-blue-500 focus:outline-none transition-all duration-200 bg-white hover:bg-gray-50';
const labelClasses = 'text-gray-700 font-medium mb-1 block';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setErrorMessage('');
    setIsLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success(
        'If there is an account associated with that email, a link to reset password will be sent. Please check your email for further instructions.',
        { duration: 10000 },
      );
      navigate(PATHS.LOGIN);
    } catch (error) {
      if (error instanceof FirebaseError) {
        setErrorMessage(error.message);
      } else {
        toast.error(
          `An unexpected error occurred. Please try again, or contact ${DEV_TEAM_EMAIL}.`,
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (currentUser) return <Navigate to={PATHS.CHAT} replace />;

  return (
    <div className='min-h-screen w-full flex flex-col items-center bg-gradient-to-br from-blue-500 to-blue-600 px-4 pt-24 lg:pt-0 lg:justify-center'>
      <LogoAndTitle />
      <div className='w-full max-w-md'>
        <div className='mb-4 text-center text-white'>
          <h1 className='text-3xl font-bold mb-2'>Reset your password</h1>
          <p className='text-blue-100 font-medium'>
            Enter your email and we'll send you instructions
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className='w-full bg-white shadow-xl rounded-2xl p-6 space-y-4'
        >
          {errorMessage && (
            <div className='p-3 rounded-lg bg-red-50 border border-red-200'>
              <p className='text-red-600 text-sm'>{errorMessage}</p>
            </div>
          )}

          <div>
            <label htmlFor='email' className={labelClasses}>
              Email address
            </label>
            <input
              type='email'
              id='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              className={inputClasses}
              placeholder='you@example.com'
              required
            />
          </div>

          <button
            type='submit'
            disabled={isLoading}
            className={`w-full bg-blue-500 text-white rounded-xl py-3 px-4 font-bold text-lg
              ${!isLoading ? 'hover:bg-blue-600 hover:shadow-lg' : 'opacity-75'}
              transition-all duration-200`}
          >
            {isLoading ? 'Sending reset link...' : 'Send Reset Link'}
          </button>

          <div className='pt-4 text-center border-t'>
            <button
              type='button'
              onClick={() => navigate(PATHS.LOGIN)}
              className='text-gray-600 font-medium hover:text-blue-600 transition duration-200'
            >
              Back to <span className='text-blue-500'>Sign In</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
