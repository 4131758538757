import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { PATHS } from '../utils/constants';
import { useUserContext } from '../contexts/UserContext';

// Convert current date to Unix timestamp (seconds since epoch)
const currentTimestamp = Math.floor(Date.now() / 1000); //todo verify UTC

const ProtectedRoutesWrapper = () => {
  const { currentUser } = useAuthContext();
  const { user } = useUserContext();

  if (!currentUser) {
    return <Navigate to={PATHS.LOGIN} replace />;
  }

  if (user.isNewUser) {
    return <Navigate to={PATHS.REGISTRATION} replace />;
  }

  const isPlanExpired = user.planExpires && user.planExpires < currentTimestamp;
  const shouldRedirectToSubscription =
    user.planActive === false || isPlanExpired;

  if (shouldRedirectToSubscription) {
    return <Navigate to={PATHS.SUBSCRIPTION} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutesWrapper;
