import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FeatureCardProps {
  icon: IconDefinition;
  title: string;
  description: string;
}

const FeatureCard = ({ icon, title, description }: FeatureCardProps) => (
  <div className='bg-white p-6 rounded-xl shadow-lg text-center'>
    <FontAwesomeIcon icon={icon} className='text-blue-600 text-3xl mb-4' />
    <h3 className='text-xl font-semibold mb-2'>{title}</h3>
    <p>{description}</p>
  </div>
);

export default FeatureCard;
