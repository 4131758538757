import { useState } from 'react';
import {
  signOut,
  updatePassword,
  updateProfile,
  deleteUser,
} from 'firebase/auth';
import { PLEASE_EMAIL_DEV_TEAM, PROFILE_PAGE_TITLES } from '../utils/constants';
import auth from '../firebase';
import { useAuthContext } from '../contexts/AuthContext';
import { useUserContext } from '../contexts/UserContext';
import userAPI from '../api/user/userAPI';
import ProfileUpdateModal from '../components/ProfileUpdateModal';
import ProfileButton from '../components/ProfileButton';
import ProfileConfirmModal from '../components/ProfileConfirmModal';
import apiClient from '../api';
import HamburgerButton from '../components/HamburgerButton';
import toast from 'react-hot-toast';
import { FiUser, FiTrash2 } from 'react-icons/fi';

const ProfilePage = () => {
  const { currentUser } = useAuthContext();
  const { user, updateUser } = useUserContext();
  const [showUsernameTakeover, setShowUsernameTakeover] = useState(false);
  const [showNativeLanguageTakeover, setShowNativeLanguageTakeover] =
    useState(false);
  const [showPasswordTakeover, setShowPasswordTakeover] = useState(false);
  const [showDeleteDataTakeover, setShowDeleteDataTakeover] = useState(false);
  const [showDeleteAccountTakeover, setShowDeleteAccountTakeover] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogout = async () => {
    try {
      setErrorMessage('');
      await signOut(auth);
      window.location.reload();
    } catch (error) {
      setErrorMessage(
        `Error while logging out: ${(error as Error).message}. 
      ${PLEASE_EMAIL_DEV_TEAM}`,
      );
    }
  };

  const handleUserNameSubmit = async (newUserName: string) => {
    if (newUserName === user.userName) {
      toast.error('Username is the same as the current one.');
      return;
    }

    try {
      const udpatedUser = await userAPI.updateUsername(user.email, newUserName);
      updateUser(udpatedUser);

      if (!currentUser) return;

      await updateProfile(currentUser, { displayName: udpatedUser.userName });

      setShowUsernameTakeover(false);
      toast.success('Username updated');
    } catch (error) {
      setErrorMessage(`Error updating username: ${(error as Error).message}`);
    }
  };

  const handleNativeLanguageSubmit = async (newNativeLanguage: string) => {
    if (newNativeLanguage === user.nativeLanguage) {
      toast.error('Selected native language is the same as the current one.');
      return;
    }

    try {
      const udpatedUser = await userAPI.updateUser({
        userEmail: user.email,
        nativeLanguage: newNativeLanguage,
      });
      updateUser(udpatedUser);

      setShowNativeLanguageTakeover(false);
      toast.success('Native language updated');
    } catch (error) {
      setErrorMessage(
        `Error updating native language: ${(error as Error).message}`,
      );
    }
  };

  const handlePasswordSubmit = async (newPassword: string) => {
    setErrorMessage('');
    //TODO verify with current password
    if (!currentUser) return;

    try {
      await updatePassword(currentUser, newPassword);
      setShowPasswordTakeover(false);
      toast.success('Password updated');
    } catch (error) {
      setErrorMessage(`Error updating password: ${(error as Error).message}`);
    }
  };

  const handleDeleteDataConfirm = async () => {
    try {
      await userAPI.deleteUserChats(user.email);

      toast.success('Your chats have been deleted');
      setShowDeleteDataTakeover(false);
    } catch (error) {
      setErrorMessage(
        `Error deleting data: ${(error as Error).message}. 
        ${PLEASE_EMAIL_DEV_TEAM}`,
      );
    }
  };

  const handleManageSubscription = async () => {
    const { data } = await apiClient.post(
      '/api/payments/create-portal-session',
      { customerId: user.stripeCustomerId },
    );

    window.location.href = data.url;
  };

  const handleDeleteAccountConfirm = async () => {
    if (!currentUser) return;

    try {
      //TODO not working
      await deleteUser(currentUser);
    } catch (error) {
      setErrorMessage(
        `Error deleting account: ${(error as Error).message}. 
        ${PLEASE_EMAIL_DEV_TEAM}`,
      );

      return;
    }

    try {
      await userAPI.deleteUser(user.email, user.subscriptionId || '');
      toast.success('Account deleted');
      window.location.reload();
    } catch (error) {
      setErrorMessage(
        `Error deleting account: ${(error as Error).message}. 
        ${PLEASE_EMAIL_DEV_TEAM}`,
      );
    }
  };

  const getCanManageSubscription = () => {
    if (
      user.stripeCustomerId === process.env.REACT_APP_COUPON_CODE2 ||
      user.stripeCustomerId === process.env.REACT_APP_COUPON_CODE1 ||
      user.stripeCustomerId === process.env.REACT_APP_COUPON_CODE3
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className='min-h-screen bg-gradient-to-br from-gray-50 to-gray-100'>
      <div className='absolute pt-2'>
        <HamburgerButton />
      </div>

      <div className='max-w-lg mx-auto p-4'>
        <div className='grid grid-cols-1 gap-4 md:gap-6 mt-12'>
          {/* Account Settings Section */}
          <div className='bg-white rounded-xl shadow-sm p-6 md:p-8 border border-gray-100'>
            <h2 className='font-semibold text-lg md:text-xl text-gray-800 mb-4 md:mb-6 flex items-center gap-2'>
              <span className='w-7 h-7 md:w-8 md:h-8 bg-blue-50 rounded-lg flex items-center justify-center'>
                <FiUser className='w-4 h-4 md:w-5 md:h-5 text-blue-500' />
              </span>
              Account
            </h2>
            <div className='space-y-2 md:space-y-3'>
              <ProfileButton
                text={PROFILE_PAGE_TITLES.UPDATE_USERNAME}
                handleClick={() => setShowUsernameTakeover(true)}
                variant='default'
              />
              <ProfileButton
                text={PROFILE_PAGE_TITLES.UPDATE_NATIVE_LANGUAGE}
                handleClick={() => setShowNativeLanguageTakeover(true)}
                variant='default'
              />
              <ProfileButton
                text={PROFILE_PAGE_TITLES.UPDATE_PASSWORD}
                handleClick={() => setShowPasswordTakeover(true)}
                variant='default'
              />
              {getCanManageSubscription() && (
                <ProfileButton
                  text={PROFILE_PAGE_TITLES.MANAGE_SUBSCRIPTION}
                  handleClick={handleManageSubscription}
                  variant='default'
                />
              )}
              <ProfileButton
                text='Logout'
                handleClick={handleLogout}
                variant='default'
              />
            </div>
          </div>

          {/* Data Management Section */}
          <div className='bg-white rounded-xl shadow-sm p-6 md:p-8 border border-gray-100'>
            <h2 className='font-semibold text-lg md:text-xl text-gray-800 mb-4 md:mb-6 flex items-center gap-2'>
              <span className='w-7 h-7 md:w-8 md:h-8 bg-red-50 rounded-lg flex items-center justify-center'>
                <FiTrash2 className='w-4 h-4 md:w-5 md:h-5 text-red-500' />
              </span>
              Data Management
            </h2>
            <div className='space-y-2 md:space-y-3'>
              <ProfileButton
                text={PROFILE_PAGE_TITLES.DELETE_ALL_YOUR_CHATS}
                handleClick={() => setShowDeleteDataTakeover(true)}
                variant='danger'
              />
              <ProfileButton
                text={PROFILE_PAGE_TITLES.DELETE_YOUR_ACCOUNT}
                handleClick={() => setShowDeleteAccountTakeover(true)}
                variant='danger'
              />
            </div>
          </div>
        </div>

        {/* Error Message */}
        {errorMessage && (
          <div className='mt-6 md:mt-8 p-4 bg-red-50 border border-red-200 rounded-xl animate-fade-in'>
            <p className='text-red-600 text-sm'>{errorMessage}</p>
          </div>
        )}

        {/* Modals */}
        <ProfileUpdateModal
          title={PROFILE_PAGE_TITLES.UPDATE_USERNAME}
          handleSubmit={handleUserNameSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          currentUsername={user.userName}
          isOpen={showUsernameTakeover}
          onClose={() => {
            setErrorMessage('');
            setShowUsernameTakeover(false);
          }}
        />

        <ProfileUpdateModal
          title={PROFILE_PAGE_TITLES.UPDATE_NATIVE_LANGUAGE}
          handleSubmit={handleNativeLanguageSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          currentNativeLanguage={user.nativeLanguage}
          isOpen={showNativeLanguageTakeover}
          onClose={() => {
            setErrorMessage('');
            setShowNativeLanguageTakeover(false);
          }}
        />

        <ProfileUpdateModal
          title={PROFILE_PAGE_TITLES.UPDATE_PASSWORD}
          handleSubmit={handlePasswordSubmit}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          isOpen={showPasswordTakeover}
          onClose={() => {
            setErrorMessage('');
            setShowPasswordTakeover(false);
          }}
        />

        <ProfileConfirmModal
          title={PROFILE_PAGE_TITLES.DELETE_ALL_YOUR_CHATS}
          handleConfirm={handleDeleteDataConfirm}
          errorMessage={errorMessage}
          isOpen={showDeleteDataTakeover}
          onClose={() => {
            setErrorMessage('');
            setShowDeleteDataTakeover(false);
          }}
        />

        <ProfileConfirmModal
          title={PROFILE_PAGE_TITLES.DELETE_YOUR_ACCOUNT}
          handleConfirm={handleDeleteAccountConfirm}
          errorMessage={errorMessage}
          isOpen={showDeleteAccountTakeover}
          onClose={() => {
            setErrorMessage('');
            setShowDeleteAccountTakeover(false);
          }}
        />
      </div>
    </div>
  );
};

export default ProfilePage;
