import toast from 'react-hot-toast';
import apiClient from '.';
import { DEV_TEAM_EMAIL } from '../utils/constants';

interface ContactFormContent {
  email: string;
  message: string;
  name?: string;
}

const postContactFormContent = async ({
  email,
  message,
  name = 'anonymous',
}: ContactFormContent) => {
  try {
    await apiClient.post('/api/contact', {
      message,
      email,
      name,
    });
  } catch (error) {
    toast.error(
      `Error sending message, please feel free to contact ${DEV_TEAM_EMAIL} directly.`,
      {
        duration: 10000,
      },
    );
  }
};

const contactAPI = {
  postContactFormContent,
};

export default contactAPI;
