import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { allowedLanguages } from '../api/user/types';
import userAPI from '../api/user/userAPI';
import { useUserContext } from '../contexts/UserContext';
import { PATHS } from '../utils/constants';
import { useAuthContext } from '../contexts/AuthContext';
import { getFlagAndLanguage } from '../utils/utils';
import useIsWindows from '../hooks/useIsWindows';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';

const selectAndInputClasses =
  'w-full px-3 py-2 border-2 rounded-lg focus:border-blue-500 focus:outline-none transition-all duration-200';
const labelClasses = 'text-gray-700 font-medium mb-1 block';

const RegistrationPage = () => {
  const { currentUser } = useAuthContext();
  const [userName, setUserName] = useState(currentUser?.displayName ?? '');
  const [nativeLanguage, setNativeLanguage] = useState('English');
  const [otherNativeLanguage, setOtherNativeLanguage] = useState('');
  const [languageToLearn, setLanguageToLearn] = useState('Spanish');
  const [languageLevel, setLanguageLevel] = useState('Beginner');
  const [isLoading, setIsLoading] = useState(false);
  const { user, updateUser } = useUserContext();
  const navigate = useNavigate();
  const isWindows = useIsWindows();

  const effectiveNativeLanguage =
    nativeLanguage === 'Other' ? otherNativeLanguage : nativeLanguage;

  useEffect(() => {
    if (!user.isNewUser) {
      navigate(PATHS.CHAT);
    }
  }, [navigate, user.isNewUser]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    if (!userName.trim()) {
      toast.error('Please enter a username', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
      setIsLoading(false);
      return;
    }

    if (nativeLanguage === 'Other' && !otherNativeLanguage.trim()) {
      toast.error('Please specify your native language', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
      setIsLoading(false);
      return;
    }

    try {
      const updatedUser = await userAPI.updateUser({
        userEmail: user.email,
        userName,
        selectedLanguage: languageToLearn,
        nativeLanguage: effectiveNativeLanguage,
        isNewUser: false,
        settings: {
          language: languageToLearn,
          level: languageLevel,
          correctionFrequency: 'Sometimes',
          motivation: 'None selected',
          speed: 'Normal',
          nativeLanguageFrequency: 'Some',
        },
      });

      updateUser(updatedUser);
    } catch (error) {
      toast.error('Something went wrong. Please try again.', {
        style: {
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className='min-h-screen w-full flex flex-col items-center pt-20 bg-gradient-to-br from-blue-500 to-blue-600 px-4 lg:pt-0 lg:justify-center'>
      <motion.div
        initial='hidden'
        animate='visible'
        variants={containerVariants}
        transition={{ duration: 0.8, ease: 'easeOut' }}
        className='w-full max-w-md'
      >
        <div className='mb-4 text-center text-white'>
          <h1 className='text-3xl font-extrabold mb-2'>
            Welcome to Lingua Chat!
          </h1>
          <p className='font-semibold'>
            Let's personalize your learning experience
          </p>
        </div>
        <motion.form
          onSubmit={handleSubmit}
          className='bg-white p-6 rounded-2xl shadow-xl space-y-4'
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8, delay: 0.4, ease: 'easeOut' }}
        >
          <div>
            <label htmlFor='username' className={labelClasses}>
              What should we call you?
            </label>
            <input
              type='text'
              id='username'
              value={userName}
              onChange={e => setUserName(e.target.value)}
              className={selectAndInputClasses}
              placeholder='Enter your preferred name'
              required
            />
          </div>

          <div className='space-y-2'>
            <label htmlFor='native-language' className={labelClasses}>
              What's your native language?
            </label>
            <select
              id='native-language'
              value={nativeLanguage}
              onChange={e => setNativeLanguage(e.target.value)}
              className={selectAndInputClasses}
            >
              {[...allowedLanguages, 'Other'].map(language => (
                <option key={language} value={language}>
                  {isWindows
                    ? language
                    : language === 'Other'
                    ? '🌍 Other'
                    : getFlagAndLanguage(language)}
                </option>
              ))}
            </select>
            {nativeLanguage === 'Other' && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                transition={{ duration: 0.3 }}
              >
                <input
                  type='text'
                  value={otherNativeLanguage}
                  onChange={e => setOtherNativeLanguage(e.target.value)}
                  placeholder='Please specify your native language'
                  className={selectAndInputClasses}
                  required
                />
              </motion.div>
            )}
          </div>

          <div>
            <label htmlFor='learn-language' className={labelClasses}>
              Which language do you want to master?
            </label>
            <select
              id='learn-language'
              value={languageToLearn}
              onChange={e => setLanguageToLearn(e.target.value)}
              className={selectAndInputClasses}
            >
              {allowedLanguages.map(language => (
                <option key={language} value={language}>
                  {isWindows ? language : getFlagAndLanguage(language)}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor='proficiency' className={labelClasses}>
              How well do you know {languageToLearn}?
            </label>
            <select
              id='proficiency'
              value={languageLevel}
              onChange={e => setLanguageLevel(e.target.value)}
              className={selectAndInputClasses}
            >
              <option value='Beginner'>Beginner</option>
              <option value='Intermediate'>Intermediate</option>
              <option value='Advanced'>Advanced</option>
            </select>
          </div>

          <button
            type='submit'
            disabled={isLoading}
            className={`w-full bg-blue-500 text-white rounded-xl py-4 font-bold text-lg
              ${
                !isLoading
                  ? 'hover:bg-blue-600 hover:shadow-lg transform hover:-translate-y-0.5'
                  : 'opacity-75'
              }
              transition-all duration-200 ease-in-out`}
          >
            {isLoading ? (
              <span className='flex items-center justify-center'>
                <svg
                  className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    strokeWidth='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
                Setting up your profile...
              </span>
            ) : (
              "Let's Start Learning! 🚀"
            )}
          </button>
        </motion.form>
      </motion.div>
    </div>
  );
};

export default RegistrationPage;
