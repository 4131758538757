export const PATHS = {
  LANDING: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  CHAT: '/chat',
  TEACHERS: '/teachers',
  LESSONS: '/lessons',
  REVIEW: '/review',
  PROFILE: '/profile',
  REGISTRATION: '/registration',
  ROLE_PLAY: '/role-play',
  SUBSCRIPTION: '/subscription',
};

export const PAGES = {
  HOME: 'Home',
  CHAT: 'Chat',
  TEACHERS: 'Teachers',
  LESSONS: 'Lessons',
  REVIEW: 'Review',
  PROFILE: 'Profile',
  ROLE_PLAY: 'Role play',
};

export enum SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export const SPINNER_CLASSES = {
  SMALL: 'h-4 w-4 border-2',
  MEDIUM: 'h-8 w-8 border-2',
  LARGE: 'h-16 w-16 border-4',
} as const;

export const MEDIA_QUERIES = {
  DESKTOP: '(min-width: 1024px)',
  LANDSCAPE: '(orientation: landscape)',
} as const;

export const EVENTS = {
  CLICK: 'click',
  SCROLL: 'scroll',
} as const;

export const LEVEL = {
  BEGINNER: 'Beginner',
  INTERMEDIATE: 'Intermediate',
  ADVANCED: 'Advanced',
} as const;

//todo rename (not just about levels)
export const LEVEL_SETTINGS_RADIO_NAMES = {
  LEVEL: 'level',
  CORRECTION_FREQ: 'correctionFrequency',
  SPEED: 'speed',
  NATIVE_LANG_FREQ: 'nativeLanguageFrequency',
  TEXT_TO_TEXT_MODEL: 'textToTextModel',
} as const;

//todo rename
export const LEVEL_SETTINGS_RADIO_TITLES = {
  LEVEL: 'Proficiency Level',
  CORRECTION_FREQ: 'Should the teacher correct you?',
  SPEED: 'Speaking Speed',
  NATIVE_LANG_FREQ:
    "Should the teacher use your native language if you don't understand?",
  TEXT_TO_TEXT_MODEL: 'AI model selection',
} as const;

//todo rename to options?
export const CORRECTION_FREQ = {
  NEVER: 'Never',
  SOMETIMES: 'Sometimes',
  ALWAYS: 'Always',
} as const;

//todo rename to options?
export const SPEED = {
  SLOW: 'Slow',
  NORMAL: 'Normal',
} as const;

//todo rename to options?
export const NATIVE_LANG_FREQ = {
  NONE: 'None',
  SOME: 'Some',
} as const;

export const TEXT_TO_TEXT_MODEL_OPTIONS = {
  FAST: 'Fast',
  ACCURATE: 'Accurate',
} as const;

export const MODELS = {
  OPEN_AI: {
    THREE_POINT_FIVE_TURBO: 'gpt-3.5-turbo',
    FOUT_OMNI_MINI: 'gpt-4o-mini',
  },
  GROQ: {
    MIXTRAL_8x7B: 'mixtral-8x7b-32768',
  },
};

export const LANGUAGES = {
  SPANISH: 'Spanish',
  RUSSIAN: 'Russian',
  FRENCH: 'French',
  ENGLISH: 'English',
  GERMAN: 'German',
  ITALIAN: 'Italian',
  PORTUGUESE: 'Portuguese',
  CHINESE: 'Chinese',
} as const;

export const LANGUAGES_WITH_TRANSLITERATION: string[] = [
  LANGUAGES.CHINESE,
  LANGUAGES.RUSSIAN,
];

export const MESSAGE_ROLES = {
  USER: 'user',
  ASSISTANT: 'assistant',
  TYPING_ANIMATION: 'typingAnimation',
};

export const DEV_TEAM_EMAIL = 'linguachatdevteam@gmail.com';
export const PLEASE_EMAIL_DEV_TEAM = `Please try again. If it still doesn't work, please email the dev team at ${DEV_TEAM_EMAIL} (please copy and paste the error message into the email if you can).`;

export const PROFILE_PAGE_TITLES = {
  UPDATE_NATIVE_LANGUAGE: 'Update native language',
  LOGOUT: 'Logout',
  UPDATE_USERNAME: 'Update username',
  UPDATE_PASSWORD: 'Update password',
  DELETE_ALL_YOUR_CHATS: 'Delete all chats',
  DELETE_YOUR_ACCOUNT: 'Delete account',
  MANAGE_SUBSCRIPTION: 'Manage subscription',
};

export const DISCORD_SERVER_INVITE = 'https://discord.gg/GHZJMRTNwz';

export const STEPS_SELECTOR_CLASSES = {
  CHANGE_LANGUAGE_OR_TEACHER_BUTTON: 'change-language-or-teacher-button',
  SETTINGS_BUTTON: 'settings-button',
  AUDIO_TOGGLE: 'audio-toggle',
  HAMBURGER_BUTTON: 'hamburger-button',
  CHAT_HEADER: 'chat-header',
  CORRECTION_BUTTON: 'correction-button',
  TRANSLATION_BUTTON: 'translation-button',
  SUGGESTION_BUTTON: 'suggestion-button',
  TRANSLITERATION_BUTTON: 'transliteration-button',
  SPEAKER_BUTTON: 'speaker-button',
};

export const STEPS_SELECTORS = {
  CHANGE_LANGUAGE_OR_TEACHER_BUTTON_SELECTOR:
    '.' + STEPS_SELECTOR_CLASSES.CHANGE_LANGUAGE_OR_TEACHER_BUTTON,
  SETTINGS_BUTTON_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.SETTINGS_BUTTON,
  AUDIO_TOGGLE_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.AUDIO_TOGGLE,
  HAMBURGER_BUTTON_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.HAMBURGER_BUTTON,
  CHAT_HEADER_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.CHAT_HEADER,
  TRANSLATION_BUTTON_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.TRANSLATION_BUTTON,
  SUGGESTION_BUTTON_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.SUGGESTION_BUTTON,
  CORRECTION_BUTTON_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.CORRECTION_BUTTON,
  TRANSLITERATION_BUTTON_SELECTOR:
    '.' + STEPS_SELECTOR_CLASSES.TRANSLITERATION_BUTTON,
  SPEAKER_BUTTON_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.SPEAKER_BUTTON,
};

export const TRANSPARENT_GIF =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

//todo use this beautiful shit
export const ERROR_MESSAGES = {
  LOGIN_FAILED:
    "Oops! The password and email are having a lovers' quarrel. Want to try that again? 💔",

  TRIAL_EXPIRED:
    "Time flies when you're having fun! Your trial just moonwalked into the sunset. 🌅 Ready to upgrade?",

  NETWORK_ERROR:
    'Looks like the internet is taking a coffee break ☕. Can you check your connection?',

  INVALID_EMAIL:
    'That email address is about as real as my chances of beating ChatGPT in chess. Try another? 📧',

  SERVER_ERROR:
    "Our server is having an existential crisis. What does it all mean?! It's not you, it's us. 🤖",

  SUBSCRIPTION_FAILED:
    'The payment processor is being pickier than a toddler at dinner time. Want to try again? 💳',

  GENERIC_ERROR:
    'Something went wrong, and our debug hamsters are working overtime to fix it! 🐹',
};

//todo hahaha
export const TEACHER_PROFILES = {
  'luca-romano': {
    name: 'Luca Romano',
    origin: 'Italy',
    bio: "A former barista who judges your Italian grammar as harshly as he judges people who order cappuccino after noon. Will occasionally stop mid-lesson to passionately debate whether pineapple belongs on pizza (it doesn't).",
    catchphrase: "That's not how my nonna would say it! 🤌",
    teachingStyle:
      "Gestures wildly while explaining verb conjugations. Claims he can't teach without moving his hands.",
  },

  'lukas-schneider': {
    name: 'Lukas Schneider',
    origin: 'Germany',
    bio: "So efficient he can teach you German grammar in under 3 minutes. Unfortunately, it's a 45-minute lesson. Spends the remaining time organizing his virtual desk.",
    catchphrase:
      'That was technically correct, which is the best kind of correct.',
    teachingStyle: 'Has a spreadsheet for everything. Everything.',
  },

  'luisa-ramirez': {
    name: 'Luisa Ramírez',
    origin: 'Spain',
    bio: "Claims she doesn't need sleep because she runs on pure salsa energy. Will turn any grammar mistake into a dance metaphor. Has never started a lesson before 10 PM.",
    catchphrase:
      "¡Ay, casi! (That's like dancing salsa but stepping on your partner's foot)",
    teachingStyle: 'Teaches subjunctive mood through telenovela scenarios',
  },

  'julien-dubois': {
    name: 'Julien Dubois',
    origin: 'France',
    bio: "Insists he's not actually French, he's Parisian. Will sigh dramatically at pronunciation errors but claims it's just his 'natural way of breathing'.",
    catchphrase: '*existential sigh* ...close enough.',
    teachingStyle:
      "Teaches through philosophical debates about why the word 'butterfly' is 'papillon'",
  },

  'isabella-rodriguez': {
    name: 'Isabella Rodriguez',
    origin: 'Argentina',
    bio: 'Claims she learned English by binge-watching every season of Friends in 48 hours. Refuses to teach on World Cup match days. Will explain subjunctive tense using meme references.',
    catchphrase:
      "How YOU doin' with that homework? (Yes, that was a Friends reference)",
    teachingStyle: 'Turns grammar rules into soccer/fútbol strategies',
  },
};
