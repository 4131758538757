import { useEffect } from 'react';
import { useTour } from '@reactour/tour';
import ChatHeader from '../components/ChatHeader';
import ChatContainer from '../components/ChatContainer';
import useChat from '../hooks/useChat';
import { useUserContext } from '../contexts/UserContext';
import userAPI from '../api/user/userAPI';

const ChatPage = () => {
  const { setIsOpen, setCurrentStep } = useTour();
  const { user, updateUser } = useUserContext();
  const {
    messages,
    areInputButtonsDisabled,
    latestAudioMessageId,
    handleTextSubmit,
    handleMP3Submit,
    isDeletingChat,
    handleDeleteClick,
    handleDeleteConfirm,
    handleDeleteCancel,
    isDeleteModalOpen,
    playingStateMap,
    setPlayingStateMap,
    stopAllAudios,
    audioMap,
    setAudioMap,
  } = useChat();

  /**
   * If user has not seen tutorial, open it and update user's
   * `hasUserSeenTutorial` to true
   */
  useEffect(() => {
    const updateHasUserSeenTutorial = async () => {
      const updatedUser = await userAPI.updateUser({
        userEmail: user.email,
        hasUserSeenTutorial: true,
      });

      updateUser(updatedUser);
    };

    if (!user.hasUserSeenTutorial) {
      setCurrentStep(0);
      setIsOpen(true);

      updateHasUserSeenTutorial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentStep, setIsOpen]);

  return (
    //todo rvw why is this div here, maybe just use fragment
    <div className='flex flex-col h-full'>
      <ChatHeader
        {...{
          stopAllAudios,
          setPlayingStateMap,
          isDeletingChat,
          handleDeleteClick,
          handleDeleteConfirm,
          handleDeleteCancel,
          isDeleteModalOpen,
          isDisabled: messages.length < 2,
        }}
      />
      <ChatContainer
        {...{
          stopAllAudios,
          audioMap,
          setAudioMap,
          setPlayingStateMap,
          playingStateMap,
          messages,
          areInputButtonsDisabled,
          latestAudioMessageId,
          handleTextSubmit,
          handleMP3Submit,
        }}
      />
    </div>
  );
};

export default ChatPage;
