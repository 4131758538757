import TryForFreeButton from './TryForFreeButton';

const FollowUpCTA = () => {
  return (
    <section className='text-white pt-8 mb-10'>
      <div className='container mx-auto px-4 text-center'>
        <h2 className='text-2xl md:text-3xl font-bold mb-6'>
          Start Your Language Learning Journey Today!
        </h2>
        <TryForFreeButton />
      </div>
    </section>
  );
};

export default FollowUpCTA;
