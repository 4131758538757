import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../utils/constants';

const TryForFreeButton = () => {
  const navigate = useNavigate();

  return (
    <button
      type='button'
      onClick={() => navigate(PATHS.SIGNUP)}
      className='px-6 py-3 bg-white text-black shadow-lg rounded-xl hover:bg-gray-100 w-44'
    >
      <p className='font-bold'>Try for free</p>
      <p className='text-sm font-semibold'>No card required!</p>
    </button>
  );
};

export default TryForFreeButton;
