const WhyUs = () => {
  const features = [
    {
      ai: 'Available 24/7',
      traditional: 'Time zone issues, etc.',
      aiEmoji: '⚡',
      traditionalEmoji: '⏰',
    },
    {
      ai: 'Unlimited conversations',
      traditional: '$7 to $25+ USD per session',
      aiEmoji: '🔄',
      traditionalEmoji: '💸',
    },
    {
      ai: 'No anxiety',
      traditional: 'Stressful',
      aiEmoji: '😁',
      traditionalEmoji: '😰',
    },
    {
      ai: 'Adapts to you instantly',
      traditional: 'Fixed teaching methods',
      aiEmoji: '🎯',
      traditionalEmoji: '📝',
    },
    {
      ai: 'Talk about anything',
      traditional: 'Some topics uncomfortable',
      aiEmoji: '💬',
      traditionalEmoji: '🤐',
    },
    {
      ai: 'Optional feedback on every message',
      traditional: "Can't correct every single detail",
      aiEmoji: '✅',
      traditionalEmoji: '❌',
    },
  ];

  return (
    <section className='max-w-3xl mx-auto px-3 py-8 sm:px-6 lg:px-8'>
      <h2 className='text-2xl md:text-3xl font-bold text-white text-center mb-6'>
        Why AI Changes Everything
      </h2>

      <div className='bg-white rounded-xl shadow-lg p-8'>
        <div className='grid grid-cols-2 gap-8 mb-4'>
          <h3 className='text-2xl font-bold text-blue-600'>AI Tutors</h3>
          <h3 className='text-2xl font-bold text-gray-600'>Traditional</h3>
        </div>

        <div className='space-y-4'>
          {features.map((feature, index) => (
            <div key={index} className='grid grid-cols-2 gap-8'>
              <div className='flex items-center gap-3'>
                <span role='img' aria-label='happy' className='text-xl'>
                  {feature.aiEmoji}
                </span>
                <p className='text-gray-900 font-medium'>{feature.ai}</p>
              </div>
              <div className='flex items-center gap-3'>
                <span role='img' aria-label='sad' className='text-xl'>
                  {feature.traditionalEmoji}
                </span>
                <p className='text-gray-600 font-medium'>
                  {feature.traditional}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
