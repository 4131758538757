import { useState, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { useAuthContext } from '../contexts/AuthContext';
import { DEV_TEAM_EMAIL, PATHS } from '../utils/constants';
import auth from '../firebase';
import LogoAndTitle from '../components/LogoAndTitle';
import toast from 'react-hot-toast';

const provider = new GoogleAuthProvider();

const inputClasses =
  'w-full px-3 py-2 border-2 rounded-lg focus:border-blue-500 focus:outline-none transition-all duration-200 bg-white hover:bg-gray-50';
const labelClasses = 'text-gray-700 font-medium mb-1 block';

const LoginPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentUser) navigate(PATHS.CHAT);
  }, [currentUser, navigate]);

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    setErrorMessage('');
    setIsLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate(PATHS.CHAT);
    } catch (error) {
      if (error instanceof FirebaseError) {
        setErrorMessage(error.message);
      } else {
        toast.error(
          `An unexpected error occurred. Please try again, or contact ${DEV_TEAM_EMAIL}.`,
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    try {
      await signInWithPopup(auth, provider);
      navigate(PATHS.CHAT);
    } catch (error) {
      if (error instanceof FirebaseError) {
        setErrorMessage(error.message);
      } else {
        toast.error(
          `An unexpected error occurred. Please try again, or contact ${DEV_TEAM_EMAIL}.`,
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='min-h-screen w-full flex flex-col items-center bg-gradient-to-br from-blue-500 to-blue-600 px-4 pt-24 lg:pt-0 lg:justify-center'>
      <LogoAndTitle />
      <div className='w-full max-w-md'>
        <div className='mb-4 text-center text-white'>
          <h1 className='text-3xl font-bold mb-2'>Welcome back!</h1>
          <p className='text-blue-100 font-medium'>
            Continue your language learning journey
          </p>
        </div>

        <form
          onSubmit={handleLogin}
          className='w-full bg-white shadow-xl rounded-2xl p-6 space-y-4'
        >
          {errorMessage && (
            <div className='p-3 rounded-lg bg-red-50 border border-red-200'>
              <p className='text-red-600 text-sm'>{errorMessage}</p>
            </div>
          )}

          <div>
            <label htmlFor='email' className={labelClasses}>
              Email address
            </label>
            <input
              type='email'
              id='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              className={inputClasses}
              placeholder='you@example.com'
              required
            />
          </div>

          <div>
            <label htmlFor='password' className={labelClasses}>
              Password
            </label>
            <input
              type='password'
              id='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              className={inputClasses}
              placeholder='••••••••'
              required
            />
          </div>

          <button
            type='submit'
            disabled={isLoading}
            className={`w-full bg-blue-500 text-white rounded-xl py-3 px-4 font-bold text-lg
              ${!isLoading ? 'hover:bg-blue-600 hover:shadow-lg' : 'opacity-75'}
              transition-all duration-200`}
          >
            {isLoading ? 'Signing in...' : 'Sign In'}
          </button>

          {/* <div className='relative my-6'>
            <div className='absolute inset-0 flex items-center'>
              <div className='w-full border-t border-gray-300'></div>
            </div>
            <div className='relative flex justify-center text-sm'>
              <span className='px-2 bg-white text-gray-500'>
                Or continue with
              </span>
            </div>
          </div> */}

          <div className='w-full'>
            <GoogleLoginButton
              onClick={handleGoogleSignIn}
              style={{
                fontSize: '18px',
                fontWeight: '600',
                padding: '12px',
                borderRadius: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span>Sign in with Google</span>
            </GoogleLoginButton>
          </div>

          <div className='flex flex-col gap-3 text-gray-600 font-medium'>
            <button
              type='button'
              onClick={() => navigate(PATHS.FORGOT_PASSWORD)}
              className='hover:text-blue-600 cursor-pointer'
            >
              Forgot your password?
            </button>
            <button
              type='button'
              onClick={() => navigate(PATHS.SIGNUP)}
              className='hover:text-blue-600 cursor-pointer'
            >
              Need an account? <span className='text-blue-500'>Sign up</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
