import { useTeachersContext } from '../contexts/TeachersContext';
import { useUserContext } from '../contexts/UserContext';
import LanguageDropdown from '../components/LanguageDropdown';
import Teachers from '../components/Teachers';
import Spinner from '../components/Spinner';
import { SIZES } from '../utils/constants';
import HamburgerButton from '../components/HamburgerButton';

const TeachersPage = () => {
  const { user } = useUserContext();
  const { teachersByLanguageMap } = useTeachersContext();

  if (
    !user ||
    !user.selectedLanguage ||
    Object.keys(teachersByLanguageMap).length < 2
  ) {
    return <Spinner fullScreen size={SIZES.LARGE} />;
  } //note prolly dont need this anymore due to context/appinit refactor i guess

  return (
    <div className='h-screen grid grid-rows-[auto,1fr]'>
      <TeachersHeader />
      <Teachers teachers={teachersByLanguageMap[user.selectedLanguage]} />
    </div>
  );
};

const TeachersHeader = () => (
  <div className='w-full grid grid-cols-6 items-center py-3 bg-gray-100 relative'>
    <div>
      <HamburgerButton />
    </div>
    <div className='col-span-4 col-start-2 flex justify-center'>
      <LanguageDropdown />
    </div>
  </div>
);

export default TeachersPage;
