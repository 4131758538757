import { ReactComponent as TranslationIcon } from '../images/translate.svg';
import { STEPS_SELECTOR_CLASSES } from '../utils/constants';
import Spinner from './Spinner';

interface TranslationButtonProps {
  isFetchingTranslation: boolean;
  handleTranslationClick: () => void;
}

const TranslationButton = ({
  isFetchingTranslation,
  handleTranslationClick,
}: TranslationButtonProps) => (
  <>
    {isFetchingTranslation ? (
      <Spinner />
    ) : (
      <button
        title='Translate'
        type='button'
        onClick={handleTranslationClick}
        className={`${STEPS_SELECTOR_CLASSES.TRANSLATION_BUTTON}`}
      >
        <TranslationIcon />
      </button>
    )}
  </>
);

export default TranslationButton;
