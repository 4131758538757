import { FC } from 'react';

interface AppUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const AppUpdateModal: FC<AppUpdateModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <dialog id='app_update_modal' className='modal modal-open'>
      <div className='modal-box'>
        <h3 className='font-bold text-lg'>App Update Available</h3>
        <p className='py-4'>
          Please update to get the latest version of the app (page will
          refresh).
          <br />
          <br />
          If you wish to wait until later, click "Cancel" (please note that the
          app may not work as well without updates).
        </p>
        <div className='modal-action'>
          <button className='btn' onClick={onClose}>
            Cancel
          </button>
          <button
            className='btn  bg-blue-500 hover:bg-blue-600 text-white'
            onClick={onConfirm}
          >
            Update Now
          </button>
        </div>
      </div>
      <form method='dialog' className='modal-backdrop'>
        <button onClick={onClose}>close</button>
      </form>
    </dialog>
  );
};

export default AppUpdateModal;
