// import { RiPinyinInput } from 'react-icons/ri';

import { STEPS_SELECTOR_CLASSES } from '../utils/constants';

const PinyinButton = ({
  handleTransliterationClick,
}: {
  handleTransliterationClick: () => void;
}) => (
  <button
    title='Pinyin'
    type='button'
    onClick={handleTransliterationClick}
    className={`text-xs font-bold ${STEPS_SELECTOR_CLASSES.TRANSLITERATION_BUTTON}`}
  >
    {/* <RiPinyinInput /> */}Pinyin
  </button>
);

export default PinyinButton;
